<template>
  <div :class="['form-group', isHome ? 'home-group': '']">

    <input ref="inputElement" type="text" :class="['form-control', 'icon-left', isHome ? 'home-input': '', isEvents ? 'event-input':'']" v-model="searchWord" aria-label="Suchbegriff eingeben" :placeholder="placeholderText" v-on:keyup.enter="onEnter" @keypress.enter.prevent>
    <span @click="onEnter" style="cursor:pointer" :class="['material-icons', 'icon', isHome ? 'home-icon': '', isEvents?'home-icon':'']" aria-hidden="true">search</span>
  </div>
</div>

</template>

<script>
  export default {
    name: 'KeywordSearch',
    props: {
      initialValue: {
        type: String,
      default: ""
      },
      placeholderText: {
        type: String,
      default: "Suchbegriff eingeben..."
      },
      focusMe: {
        type: Boolean,
      default: false,
      },
      isHome: {
        type: Boolean,
      default: false,
      },
      isEvents: {
        type: Boolean,
      default: false,
      }
    },
    data() {
      return {
        searchWord: '',
        awaitingSearch: false,
      }
    },
    watch: {
      initialValue(newWord){
        console.log(newWord);
        this.searchWord = newWord;
      },
      searchWord(newWord, oldWord){
        if (!this.awaitingSearch && newWord.length > 3) {
          setTimeout(() => {
            this.$emit('searchWordChanged',this.searchWord);
            this.awaitingSearch = false;
          }, 1000); // 1 sec delay
        }
        if(newWord.length > 3){
          this.awaitingSearch = true;
        }
      },
    },
    mounted() {
      /*set the time to the initialValue*/
      this.searchWord = this.initialValue;
      if(this.focusMe){
        this.$refs.inputElement.focus();
      }
    },
    methods: {
      onEnter(){
        this.$refs.inputElement.blur();
        this.$emit('searchWordChanged',this.searchWord);
      },
      focus(){
        this.$refs.inputElement.focus();
      },
    }
  }
</script>
<style lang="scss" scoped>
  @import '@/scss/_variables.scss';
  @import '@/scss/_mixins.scss';

  .form-control {
    border: 1px solid rgba(58,58,58,.1) !important;
  }

  .home-group {
    height: auto;
    .home-input {
      border-radius: 50px;
      padding-right: 40px;
      padding: 0.375rem 1rem !important;

      @media (min-width: 992px){
        height: 50px;
        font-size: 14px;
      }
    }

    .event-input {
      border-radius: 0.25rem;
      padding-right: 40px;
      padding: 0.375rem 1rem !important;
    }

    .home-icon {
      right: 0;
    }
  }
</style>